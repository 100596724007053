import React, { useState } from "react";
import "./meetOurteam.scss"; // Make sure to include your CSS styles
import { Grid, Typography } from "@mui/material";
import Underline from "../../../assets/HP/icons/underline.png";
import Aditya from "../../../assets/Aditya.jpg";

const membersData = [
  {
    id: "m1",
    imgSmall: Aditya,
    imgLarge:
      "https://images.unsplash.com/photo-1628157588553-5eeea00af15c?q=80&w=400&auto=format&fit=crop",
    name: "Aditya Kolpuke",
    title: "Founder and Managing Partner",
    bio: `Experienced Financial Analyst with a demonstrated history of working in the environmental services industry. Skilled in Financial Applications, CFO, Operations Management, Commerce, Auditing, and Accounting. Experience of 3 years 8 months in Bureau Veritas India Pvt Ltd. Worked with NeoSOFT Technologies as an Senior Financial Analyst - FP&A`,
  },
  {
    id: "m2",
    imgSmall:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=100&auto=format&fit=crop",
    imgLarge:
      "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=400&auto=format&fit=crop",
    name: "HARSHADA TAMBOLI",
    title: "Partner",
    bio: `Experienced Financial Analyst with a demonstrated history of working in the environmental services industry.`,
  },
];

const Tile = ({ index, member, isActive, onClick }) => (
  <label
    htmlFor={member.id}
    className={`tile ${isActive ? "active" : ""}`}
    onClick={() => onClick(index)}
  >
    <img src={member.imgSmall} alt={member.name} style={{height: "7rem"}} />
  </label>
);

const Card = ({ member, isChecked }) => (
  <div
    className={`card-wrapper ${isChecked ? "active" : ""}`}
    style={{ display: isChecked ? "flex" : "none" }}
  >
    <input
      type="radio"
      id={member.id}
      name="members"
      checked={isChecked}
      readOnly
    />
    <div className="card">
      {/* <img src={member.imgLarge} className="avatar" alt={member.name} /> */}
      <div className="info">
        <div className="name">{member.name}</div>
        <div className="title">{member.title}</div>
        <div className="bio">
          {member.bio.split("\n").map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  </div>
);

function TileContainer() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <span
        style={{
          marginLeft: "2rem",
          marginTop: "4rem",
          marginBottom: "2rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12} className="topOffersItem1">
          <Typography variant="h2" className="topOffersTitle">
            About Us
          </Typography>
        </Grid>
        <Grid item xs={12} className="topOffer-underline">
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
      </span>

      <div className="wrapper">
        <div className="team-wrapper">
          <div className="members">
            {membersData.map((member, index) => (
              <Tile
                key={member.id}
                index={index}
                member={member}
                isActive={index === activeIndex}
                onClick={handleClick}
              />
            ))}
          </div>
          <div className="divider"></div>
          <div className="cards">
            {membersData.map((member, index) => (
              <Card
                key={member.id}
                member={member}
                isChecked={index === activeIndex}
              />
            ))}
          </div>
        </div>
      </div>
      <Grid item xs={12}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "4rem",
            marginBottom: "6rem",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15547.506440051546!2d77.5693403!3d13.043525200000007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1713d881bb09%3A0xfa126a6724967f12!2sH%20P%20PAI%20%26%20ASSOCIATES!5e0!3m2!1sen!2sin!4v1718665742923!5m2!1sen!2sin"
            width="80%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            class="map"
          ></iframe>
        </span>
      </Grid>
    </>
  );
}

export default TileContainer;
