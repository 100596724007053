import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";

const blue = {
  100: "#DAECFF",
  200: "#D1B06B",
  400: "#D1B06B",
  500: "#D1B06B",
  600: "#D1B06B",
  900: "#D1B06B",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 2px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#FFFFFF",
            "& label": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            color: "#FFFFFF",
            width: "100%",
            "&::before": {
              color: "#FFFFFF",
              borderBottom: "1px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              color: "#FFFFFF",
              borderBottom: "1px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              color: "#FFFFFF",
              borderBottom:
                "1px solid var(--TextField-brandBorderFocusedColor)",
            },
            "&.Mui-focused": {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
  });

const GoogleFormSubmit = () => {
  const outerTheme = useTheme();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
    // Add more fields as needed
  });
  const [submitted, setSubmitted] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formId = "1FAIpQLSfxJmBej4JYZHiOHqMmvdbeHBk6lw7CVyKpSQ1VAL00McVoEg";
    const formResponseUrl = `https://docs.google.com/forms/d/e/${formId}/formResponse`;

    const formEntries = {
      "entry.2005620554": formData.name, // Replace with your actual entry ID for the Email field
      "entry.1166974658": formData.phone,
      "entry.137940793": formData.email,
      "entry.1770665555": formData.service,
      "entry.839337160": formData.message,
      // Add more fields as needed
    };

    const formBody = new URLSearchParams(formEntries).toString();

    try {
      const response = await fetch(formResponseUrl, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Accept: "*/*",
        },
        body: formBody,
      });

      if (response.ok) {
        setSubmitted(1);
        console.log("Form submitted successfully!");
      } else {
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setSubmitted(1);
  };

  return (
    <>
    <div style={{height: "10vh", background: "#FFFFFF"}}>

    </div>
      {submitted ? (
        <div
          className="formContainer thankyou"
          style={{ color: "#FFFFFF", marginBottom: "20rem" }}
        >
          <h2>Thank you for contacting us!</h2>

          <p>Our expert team will contact you soon</p>
        </div>
      ) : (
        <div style={{background: "#FFFFFF"}}>
          <form onSubmit={handleSubmit} className="formContainer">
            {/* <div style={{ marginTop: "20rem" }}>
      <label>Name:</label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
    </div> */}
            {/* <div>
      <label>Email:</label>
      <input
        type="phone"
        name="phone"
        value={formData.email}
        onChange={handleChange}
      />
    </div> */}
            {/* Add more fields as needed */}
            <span
              className="formcomp"
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div className="inputContainer">
                <ThemeProvider
                  theme={customTheme(outerTheme)}
                  className="inputContainer"
                >
                  <TextField
                    label="Name"
                    variant="standard"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />

                  <TextField
                    label="Email"
                    variant="standard"
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />

                  <TextField
                    label="Phone Number"
                    variant="standard"
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />

                  <TextField
                    label="What service are you looking for?"
                    variant="standard"
                    type="text"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                  />
                </ThemeProvider>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="rightContainer"
              >
                <Textarea
                  className="textarea"
                  minRows={4}
                  aria-label="Your message"
                  placeholder="Your message"
                  defaultValue=""
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
                <button
                  type="submit"
                  className="submitButton"
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </button>
              </div>
            </span>
          </form>
        </div>
      )}
    </>
  );
};

export default GoogleFormSubmit;
