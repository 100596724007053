import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./TopSection.scss";
import Topimg from "../../../assets/Aditya.jpg";
import DocIcon from "../../../assets/HP/icons/doc.png";
import Underline from "../../../assets/HP/icons/underline.png";


export default function TopSection() {
  return (
    <div className="aboutUsContainer">
      {/* title featured listing of the week */}
      {/* <Typography variant='h1' className='featuredListingTitle'>
     About Us
    </Typography> */}

      {/* container for left n right containers */}
      <Grid
        container
        className="featuredListBox"
        direction={{ md: "row", xs: "column" }}
        justifyContent={{ md: "center", xs: "center" }}
        alignItems={{ md: "center", xs: "center" }}
      >
        {/* left container */}
        <Grid item className="leftContainer" md={6}>
          <img className="topImg" src={Topimg} alt="img" />
          {/* <img className='bottomImg' src={BottomImg} alt='img'/> */}
        </Grid>

        {/* right container */}
        <Grid
          item
          className="rightContainer"
          container
          direction={{ md: "column", xs: "column" }}
          rowSpacing={{ md: 2, xs: 4 }}
          md={6}
          justifyContent={{ md: "center", xs: "center" }}
          alignItems={{ md: "flex-start", xs: "center" }}
        >
          <Grid item>
            <Typography variant="h4" className="rightContainerTitle">
              I'm Aditya, a renowned High Court Lawyer
            </Typography>
          </Grid>

          <Grid item>
            <img style={{ width: "4rem" }} src={Underline} />
          </Grid>

          <Grid item>
            <Typography variant="body1" className="rightContainerDescr">
            I am a renowned lawyer practicing at the High Court of India, with expertise in litigation, constitutional law, and human rights. Throughout my career, I have been involved in landmark cases that have shaped national policies and legal precedents. My dedication to justice and commitment to social causes have earned me respect in the legal community.            </Typography>
          </Grid>

          

          {/* <Grid item>
            <Typography variant="body1" className="rightContainerDescr">
              <b>And this can be only achieved by team work</b>{" "}
            </Typography>
          </Grid> */}

          {/* <Grid item container rowSpacing={3.3} columnSpacing={2.2} >
              {featuredList.map(obj=>(
                <Grid item md={4} xs={6}>
                <Typography className='rightContainerFeatures' variant='h6'>{obj.feature}</Typography>
                <Typography className='rightContainerFeatureValues' variant='p'>{obj.value}</Typography>
              </Grid>
              ))}
          </Grid> */}

          {/* <Grid item >
            <button id='rightContainerButton' >
              YES! I WANT BOOK “OFFICE PACKAGE”
            </button>
          </Grid> */}
          <span
            style={{
              marginTop: "2rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <div>
              <div
                className="success-facts"
                style={{
                  width: "10rem",
                  height: "4rem",
                  backgroundColor: "#3B434E",
                }}
              >
                <span className="content">
                  <img className="doc-icon" src={DocIcon} />
                  <span>
                    <p>95%</p>
                    <p>Case Success</p>
                  </span>
                </span>
              </div>
            </div>

            <div>
              <div
                className="success-facts"
                style={{
                  width: "10rem",
                  height: "4rem",
                  backgroundColor: "#D1B06B",
                }}
              >
                <span className="content" style={{ color: "#1F2732" }}>
                  <span
                    style={{
                      padding: "8px",
                      background: "#1F2732",
                      color: "white",
                      fontSize: "1.2rem",
                      fontWeight: 600,
                      fontFamily: "Rubik",
                      borderRadius: "2px",
                    }}
                  >
                    35+
                  </span>
                  <span>
                    <p>Years</p>
                    <p>Experience</p>
                  </span>
                </span>
              </div>
            </div>
          </span>
        </Grid>
      </Grid>
    </div>
  );
}
