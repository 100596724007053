import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useRef } from "react";
import "./AboutUs.scss";
import Underline from "../../../assets/HP/icons/underline.png";

import Justice from "../../../assets/HP/aboutUs/icons/justice.png";
import Protect from "../../../assets/HP/aboutUs/icons/protect.png";
import Relationship from "../../../assets/HP/aboutUs/icons/relationship.png";
import Fight from "../../../assets/HP/aboutUs/icons/fight.png";
import AboutUsCard from "./cards/aboutUsCard";

const card_details_array = [
  {
    id: 1,
    img: Justice,
    loc: "Offering advice grounded in thorough legal research and current practices.",
    descr: "Research-Based Advisory",
  },
  {
    id: 2,
    img: Protect,
    loc: "Providing top-tier legal counsel that meets stringent standards.",
    descr: "High-Quality Advice",
  },
  {
    id: 3,
    img: Relationship,
    loc: "Ensuring partners are engaged in all deals and transactions.",
    descr: "Partner Involvement",
  },
  {
    id: 4,
    img: Fight,
    loc: "Being proactive and available around the clock.",
    descr: "Responsiveness",
  },
];

export default function AboutUs() {

  return (
    <div
      style={{ background: "#161D27", paddingBottom: "4rem" }}
      className="topOffers-main"
    >
      <>
        <Grid
          container
          direction="column"
          id="aboutUsContainer"
          justifyContent="center"
        >
          {/* heading */}
          <Grid item xs={12} className="topOffersItem1">
            <Typography variant="h2" className="topOffersTitle">
              About Us
            </Typography>
          </Grid>

          <Grid item xs={12} className="topOffer-underline">
            <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
          </Grid>

          {/* description and button */}
          <Grid
            item
            container
            direction={{ md: "row", xs: "column" }}
            className="topOffersItem2"
          >
            <Grid item md={12}>
              <Typography variant="body1" className="topOffersDescr">
              Founded in 2023, HP And Associates is a prominent Indian law firm with offices in Mumbai, Navi Mumbai, and Pune. The firm offers a range of services including transactional, regulatory, advisory, dispute resolution, and tax support. Serving a diverse clientele—domestic and international companies, banks, financial institutions, NBFCs, funds, promoter groups, and public sector entities—the firm excels in handling complex domestic and international matters.
              </Typography>
            </Grid>
          </Grid>

          <span style={{paddingTop: "4rem"}}></span>
          {/* card carsousel */}
          {card_details_array.map((obj) => (
            <Grid
              item
              md={6}
              xs={12}
              className="topOffersItem4"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <AboutUsCard
                key={obj.id}
                cost={obj.cost}
                loc={obj.loc}
                descr={obj.descr}
                imgsrc={obj.img}
              />
            </Grid>
          ))}
          
        </Grid>
      </>
    </div>
  );
}
