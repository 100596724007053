import React from "react";

function FaqsCard(props) {
  return (
    <div className="faq-questions">
      <h2 style={{fontFamily: "Merriweather", marginBottom: "1rem"}}>{props.question}</h2>
      <p style={{fontSize: "1rem"}}>{props.answer}</p>
    </div>
  );
}
export default FaqsCard;
