import { Grid, Typography } from "@mui/material";
import React from "react";
import "../disclaimer.scss";
import HomeBg from "../../../assets/HP/services/ServicesMain.png";
import Underline from "../../../assets/HP/icons/underline.png";

export default function TopContainer() {
  const windowWidth = window.innerWidth;
  return (
    <div style={{ marginTop: "5vh" }}>
      <Grid container className="disclaimer-points" spacing={1}>
        <Grid item md={4}>
          <h2>Disclaimer</h2>
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
        <Grid item md={8} style={{marginBottom: "4rem"}}>
          <Typography>
          <h5 style={{marginBottom: "1rem"}} id="modal-popover" variant="h6">
            In accordance with the regulations set forth by the Bar Council of
            India, attorneys and law firms are prohibited from soliciting work
            or advertising in any form. By selecting the “I Agree” button, the
            user acknowledges and agrees to the following:
          </h5>
          <ul style={{fontSize: "0.8rem", lineHeight: "1.2rem"}}>
            <li>
              HP and Associates, along with its members, has not engaged in any
              form of advertisement, personal communication, solicitation,
              invitation, or any other inducement to solicit work through this
              website.
            </li>
            <li>
              All content and information on this website, except for statutory
              instruments or judicial precedents, is the property of HP and
              Associates. No part of this content may be used or adapted without
              the express prior written consent of HP and Associates.
            </li>
            <li>
              The user seeks to obtain information about HP and Associates
              solely for personal use and information.
            </li>
            <li>
              The information provided on this website is offered solely at the
              user’s request. Any materials downloaded or information obtained
              from this website are provided at the user’s discretion, and no
              lawyer-client relationship is established using this site.
            </li>
            <li>
              The content on this website is intended for informational purposes
              only and should not be construed as an advertisement or
              solicitation.
            </li>
            <li>
              HP and Associates assumes no responsibility for the interpretation
              or use of the information provided on this website. The firm does
              not offer any warranty, express or implied, regarding the accuracy
              or completeness of the content available on www.HPSA.com,
              including any hyperlinks.
            </li>
            <li>
              The content on this website does not constitute legal advice and
              should not be relied upon as a substitute for professional legal
              counsel.
            </li>
            <li>
              HP and Associates is not liable for any actions taken by users
              based on the information provided on this website or any external
              links contained herein.
            </li>
            <li>
              This website is intended as a resource for informational purposes
              and is not guaranteed to be complete or current. HP and Associates
              disclaims all liability for any loss or damage resulting from
              errors or omissions in the content, whether such errors or
              omissions arise from negligence, accident, or other causes.{" "}
            </li>
          </ul>
          </Typography>
        </Grid>

        {/* <Grid item md={4}>
          <h2>Photography</h2>
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
        <Grid item md={8} style={{marginBottom: "4rem"}}>
          <Typography>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </Typography>
        </Grid>

        <Grid item md={4}>
          <h2>Photography</h2>
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
        <Grid item md={8} style={{marginBottom: "4rem"}}>
          <Typography>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </Typography>
        </Grid>

        <Grid item md={4}>
          <h2>Photography</h2>
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
        <Grid item md={8} style={{marginBottom: "4rem"}}s>
          <Typography>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </Typography>
        </Grid> */}
      </Grid>
    </div>
  );
}
