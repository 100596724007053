import React, { useEffect } from "react";
import "./homePage.scss";
import HowItWorks from "./components/howitworks";
import FeaturedList from "../homePage/components/FeaturedList";
import NavHomeSearch from "../homePage/components/NavHomeSearch";
import TopOffers from "../homePage/components/TopOffers";

import Footer from "../../Components/Footer";
import PopoverComponent from "./components/PopoverComponent";

function HomePage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="homepage">
      <PopoverComponent />
      <NavHomeSearch />
      <FeaturedList />
      <TopOffers />
      <HowItWorks/>
      <Footer />
    </div>

  );
}
export default HomePage;
