import React from "react";
import "./footer.scss";
import LinkedIn from "../assets/LinkedIn.svg";
import Facebook from "../assets/Facebook.svg";
import Instagram from "../assets/Instagram.svg";
import { Grid, Stack, Typography } from "@mui/material";
import leftUnderline from "../assets/HP/icons/leftUnderline.png";
import rightUnderline from "../assets/HP/icons/rightUnderline.png";

function Footer() {
  return (
    <Grid
      id="footerContainer"
      container
      direction={{ md: "row", xs: "column" }}
      align-items={{ md: "center", xs: "center" }}
      rowSpacing={{ md: 0, xs: 8 }}
      justifyContent={{ md: "space-evenly", xs: "space-evenly" }}
      style={{ marginTop: "20vh" }}
    >
      <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        className="firstBox"
        alignItems={{ md: "flex-start", xs: "center" }}
        justifyContent={{ md: "center", xs: "center" }}
        md={12}
        xs={12}
        rowSpacing={{ md: 3, xs: 3 }}
      >
        <Grid item className="firstBoxItem1">
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <img style={{ width: "20vw" }} src={leftUnderline} />
            {/* <img className="footerLogo" src={Logo} alt="logo" /> */}
            {/* <img className="logoTitle" src={Logotitle} alt="logo-title" /> */}
            <h1 className="footertitle">HP AND ASSOCIATES</h1>
            <img style={{ width: "20vw" }} src={rightUnderline} />
          </Stack>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction={{ md: "row", xs: "row" }}
        justifyContent={{ md: "space-around", xs: "space-between" }}
        alignItems={{ md: "flex-start", xs: "flex-start" }}
        md={4}
        xs={12}
        className="secondAndThirdContainer"
      >
        <Grid item className="secondBox">
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "8rem",
            }}
          ></span>
          <Typography className="secondBoxTitle" variant="h5">
            Quick Links
          </Typography>
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "8rem",
            }}
          ></span>
          <Stack
            direction="column"
            alignItems="flex-start"
            style={{ marginTop: "1.2rem" }}
          >
            <a className="secondBoxLinks" href="/">
              Home
            </a>
            <a className="secondBoxLinks" href="/aboutUs">
              About
            </a>
            <a className="secondBoxLinks" href="/services">
              Our Services
            </a>
            <a className="secondBoxLinks" href="/contactus">
              Contact Us
            </a>
            <a className="secondBoxLinks" href="/disclaimer">
              Disclaimer
            </a>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction={{ md: "row", xs: "row" }}
        justifyContent={{ md: "space-around", xs: "space-between" }}
        alignItems={{ md: "flex-start", xs: "flex-start" }}
        md={4}
        xs={12}
        className="secondAndThirdContainer"
      >
        <Grid item className="secondBox">
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "8rem",
            }}
          ></span>
          <Typography className="secondBoxTitle" variant="h5">
            Practice Areas
          </Typography>
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "8rem",
            }}
          ></span>
          <Stack
            direction="column"
            alignItems="flex-start"
            style={{ marginTop: "1.2rem" }}
          >
            <a className="secondBoxLinks" href="/services">
              Family Law
            </a>
            <a className="secondBoxLinks" href="/services">
              Criminal Law
            </a>
            <a className="secondBoxLinks" href="/services">
              Real Estate Law
            </a>
            <a className="secondBoxLinks" href="/services">
              Business Law
            </a>
            <a className="secondBoxLinks" href="/services">
              Accident Law
            </a>
          </Stack>
        </Grid>
      </Grid>

      <Grid
        item
        container
        direction={{ md: "row", xs: "row" }}
        justifyContent={{ md: "space-around", xs: "space-between" }}
        alignItems={{ md: "flex-start", xs: "flex-start" }}
        md={4}
        xs={12}
        className="secondAndThirdContainer"
      >
        <Grid item className="secondBox">
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "8rem",
            }}
          ></span>
          <Typography className="secondBoxTitle" variant="h5">
            Contact Us
          </Typography>
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "8rem",
            }}
          ></span>
          <Stack
            direction="column"
            alignItems="flex-start"
            style={{ marginTop: "1.2rem" }}
          >
            <p
              className="secondBoxLinks"
              href=""
              style={{ marginBottom: "1.2rem" }}
            >
              <b>Navi Mumbai office:</b> Block No.305 Thacker Tower, Plot No 86,
              Sector 17, Vashi, Navi Mumbai, Maharashtra 400703, India
            </p>
            <p
              className="secondBoxLinks"
              href=""
              style={{ marginBottom: "1.2rem" }}
            >
              <b>Thane office:</b> Block No.305 Thacker Tower, Plot No 86,
              Sector 17, Vashi, Navi Mumbai, Maharashtra 400703, India
            </p>
            <a
              className="secondBoxLinks"
              href="https://mail.google.com/mail/?view=cm&fs=1&to=legal@adihitcapella.com"
              target="_blank"
            >
              <b>Email:</b> legal@adihitcapella.com
            </a>
            <a className="secondBoxLinks" href="tel: +919867280864">
              <b> Phone1:</b> +91 98672 80864
            </a>
            <a className="secondBoxLinks" href="tel: +919987880864">
              <b>Phone 2:</b> +91 99878 80864
            </a>
            <p className="secondBoxLinks">
              <b>Office hours: </b>
              Monday to Saturday: 10:00 AM – 7:30 PM Sunday: Closed
            </p>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={{ md: "row", xs: "row" }}
        justifyContent={{ md: "space-around", xs: "space-between" }}
        alignItems={{ md: "flex-start", xs: "flex-start" }}
        md={12}
        xs={12}
        style={{ color: "#FFFFFF" }}
      >
        <Grid item md={12} xs={12}>
          <span
            className="footer-divider"
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "100%",
              marginBottom: "1rem",
            }}
          ></span>
        </Grid>
        <Grid item md={2} xs={12} style={{ marginBottom: "1rem" }}>
          Copyright @ 2024
        </Grid>
        <Grid item md={4} xs={12} style={{ marginBottom: "2rem" }}>
          | Designed by{" "}
          <a style={{ color: "#D1B06B" }}>TechYatra Business Solutions</a>
        </Grid>
        <Grid item md={3} xs={0}></Grid>
        <Grid item md={3} xs={12}>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p style={{ color: "#D1B06B" }}>Follow: </p>
            <img className="socialIcons" src={Instagram} alt="Ig" />
            <a
              href="https://www.linkedin.com/company/adihit-capella/people/"
              target="_blank"
            >
              <img
                className="socialIcons"
                src={LinkedIn}
                style={{ color: "#FFFFFF" }}
                alt="ln"
              />
            </a>
            <img className="socialIcons" src={Facebook} alt="fb" />
          </span>
        </Grid>
        {/* <Grid item xs={12}>
          <span
            style={{
              height: ".1px",
              backgroundColor: "#3A4553",
              display: "flex",
              width: "100%",
              marginBottom: "4rem",
              marginTop: "1rem",
            }}
          ></span>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

export default Footer;
