import React, { useState } from "react";
import { Grid } from "@mui/material";
import "./components.scss";
import HowItWorksCard from "./cards/howitworksCard";
import Brands from "../components/brands";
import Cert1 from "../../../assets/HP/icons/cert1.png";
import Cert2 from "../../../assets/HP/icons/cert2.png";
import Cert3 from "../../../assets/HP/icons/cert3.png";
import Underline from "../../../assets/HP/icons/underlineFull.png";
import Hall from "../../../assets/HP/Hall.png"

function HowItWorks(props) {
  return (
    <div className="howitworksContainer">
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          className="howitworks-text"
          alignItems="center"
          justifyContent="center"
        >
          Membership & Licenses
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center", margin: "1rem" }}
        >
          <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
        </Grid>
        <Grid item style={{ textAlign: "center", width: "75%" }}>
          <p>
            In India, law firms are regulated and governed by various
            memberships, licenses, and regulatory bodies to ensure compliance
            with legal standards and ethical practices. Here are the primary
            memberships and licenses relevant to law firms in India
          </p>
        </Grid>
        <Grid container className="howitworksCards" spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="success-facts"
                style={{
                  width: "15rem",
                  height: "6rem",
                  backgroundColor: "#FBFBFB",
                }}
              >
                <span className="content">
                  <img
                    className="doc-icon"
                    src={Cert2}
                    style={{ height: "3.5rem" }}
                  />
                  <span>
                    <p
                      style={{
                        fontFamily: "Merriweather",
                        marginBottom: "6px",
                      }}
                    >
                      License Name
                    </p>
                    <p style={{ fontSize: ".8rem", color: "#919191" }}>
                      One line about license
                    </p>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="success-facts"
                style={{
                  width: "15rem",
                  height: "6rem",
                  backgroundColor: "#FBFBFB",
                }}
              >
                <span className="content">
                  <img
                    className="doc-icon"
                    src={Cert1}
                    style={{ height: "2.5rem" }}
                  />
                  <span>
                    <p
                      style={{
                        fontFamily: "Merriweather",
                        marginBottom: "6px",
                      }}
                    >
                      License Name
                    </p>
                    <p style={{ fontSize: ".8rem", color: "#919191" }}>
                      One line about license
                    </p>
                  </span>
                </span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                className="doc-icon"
                src={Cert3}
                style={{ height: "6rem" }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        container
        className="howitworks-text"
        alignItems="center"
        justifyContent="center"
        // style={}
      >
        Our Partners
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "center", margin: "1rem" }}
      >
        <img style={{ width: "4rem", marginTop: "1rem" }} src={Underline} />
      </Grid>
      <Brands /> */}
      <div style={{ height: "20vh", overflow: "hidden", display: 'flex',position: 'relative',paddingBottom: 100, backgroundImage: `url(${Hall})`, marginTop: "4rem" }}>
      {/* <img src={HomeBg} style={{width:"100%", position: "fixed", height: "100vh"}} className='homeBg' alt='backgroundimage'/> */}
      </div>
    </div>
  );
}
export default HowItWorks;
